import { Hidden, View, VStack } from 'native-base';
import { useCallback } from 'react';
import { colors } from 'ui/theme/colors';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { MobileNavBar } from '~/components/Layouts/v2/SearchLayout/components/MobileNavBar';
import { SEARCH_COLLECTION_CONFIG_MAP } from '~/components/Layouts/v2/SearchLayout/constants/search-collections';
import { ActivityCarousel } from '~/components/v2/ActivityCarousel';
import { useCarouselController } from '~/pages/Authenticated/v2/Home/controllers/carousels-controller';

import { HomeHeader } from './components/Header';
import Section from './components/Section';

export const Home = () => {
  const { isFilterSet, dataSourceMap, handleCarouselAction } =
    useCarouselController();

  const renderCarousels = useCallback(() => {
    const carouselCollections = Array.from(
      SEARCH_COLLECTION_CONFIG_MAP.values(),
    );

    return carouselCollections.map(({ slug, title, subTitle }) => {
      const dataSource = dataSourceMap.get(slug);
      if (!dataSource) return null;

      return (
        <Section key={slug}>
          <ActivityCarousel
            title={title.large}
            subTitle={subTitle}
            isEnabled={!!isFilterSet}
            dataSource={dataSource}
            marginBottom={6}
            onActionPress={() => handleCarouselAction(slug)}
          />
        </Section>
      );
    });
  }, [dataSourceMap, handleCarouselAction, isFilterSet]);

  return (
    <View>
      <Hidden from="md">
        <MobileNavBar
          fontColor={colors.singletons.white}
          backgroundColor={'gray.800'}
        />
      </Hidden>

      <HomeHeader />

      <VStack pt={8} minH={'100vh'} bg={colors.singletons.white}>
        <CenterContainer size={CenterContainerSize.xl}>
          <VStack>{renderCarousels()}</VStack>
        </CenterContainer>
      </VStack>
    </View>
  );
};
