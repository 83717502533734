import { HStack, VStack } from 'native-base';
import { LocationButton } from 'ui/components/v2/LocationInputs/LocationButton';
import { colors } from 'ui/theme/colors';

import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
export type MobileNavBarProps = {
  backgroundColor?: string;
  fontColor?: string;
};
export const MobileNavBar = ({
  backgroundColor = 'transparent',
  fontColor = colors.singletons.black,
}: MobileNavBarProps) => {
  const {
    location,
    openLocationModal,
    isLoading: isLocationLoading,
  } = useUserLocationController();

  return (
    <VStack backgroundColor={backgroundColor} zIndex={999} py={2} px={4}>
      <HStack w="100%" h="full" alignItems={'center'} justifyContent={'center'}>
        <LocationButton
          isLoading={isLocationLoading}
          onPress={openLocationModal}
          text={location?.rawLocation || ''}
          maxWidth="unset"
          textColor={fontColor}
          iconColor={fontColor}
        />
      </HStack>
    </VStack>
  );
};
