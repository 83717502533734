export enum RoundButtonSize {
  md,
  lg,
}

export const RoundButtonSizeMap = {
  [RoundButtonSize.lg]: '56px',
  [RoundButtonSize.md]: '32px',
};

export type RoundButtonProps = {
  icon?: string;
  imageUrl?: string;
  backgroundColor?: string;
  activeBackgroundColor?: string;
  size?: RoundButtonSize;
  isActive?: boolean;
  label?: string;
  onPress?: VoidFunction;
};
