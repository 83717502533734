import React, { useMemo } from 'react';
import { ButtonGroup } from 'ui/components/v2/ButtonGroup';
import { colors } from 'ui/theme/colors';

import { MakeButtonSkeletons } from '~/pages/Authenticated/v2/InApp/components/CategoriesSlider/utils';
import { ActivitySearchCategory } from '~/types/interfaces/activity-search/types';

export type CategoriesButtonGroupProps = {
  buttonBackgroundColor?: string;
  selected: ActivitySearchCategory | null;
  categoriesMap: Map<string, ActivitySearchCategory> | null;
  onChange?: (value: ActivitySearchCategory | null) => void;
};

export const CategoriesButtonGroup = ({
  selected,
  categoriesMap,
  buttonBackgroundColor,
  onChange,
}: CategoriesButtonGroupProps) => {
  const handleButtonGroupChange = (values: string[]) => {
    if (!categoriesMap) return;

    const categoryKey = values ? values[0] : null;
    let category = null;
    if (categoryKey) {
      category = categoriesMap.get(categoryKey);
    }
    onChange?.(category || null);
  };

  const categoriesOptions = useMemo(() => {
    if (!categoriesMap) return [];
    return Array.from(categoriesMap.values());
  }, [categoriesMap]);

  /* Background Color */
  const roundButtonBackgroundColor = buttonBackgroundColor
    ? `${buttonBackgroundColor}1A`
    : `${colors.grey.bg}1A`;

  const roundButtonActiveBackgroundColor = buttonBackgroundColor
    ? buttonBackgroundColor
    : colors.singletons.black;

  return (
    <ButtonGroup
      flexWrap={'nowrap'}
      values={selected?.key ? [selected?.key] : []}
      onChange={handleButtonGroupChange}
      isLoading={!categoriesOptions.length}
      renderSkeleton={() => MakeButtonSkeletons({ cols: 14 })}
    >
      {categoriesOptions?.map(({ key, label, icon }) => {
        const { iconName, imageUrl } = icon;
        return (
          <ButtonGroup.RoundButton
            key={key}
            value={key}
            label={label}
            icon={iconName}
            backgroundColor={roundButtonBackgroundColor}
            activeBackgroundColor={roundButtonActiveBackgroundColor}
            imageUrl={imageUrl}
          />
        );
      })}
    </ButtonGroup>
  );
};

export default React.memo(CategoriesButtonGroup);
