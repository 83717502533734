import { VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { Slider } from 'ui/components/v2/inputs/Slider';

import { SearchFilters } from '~/zustand/store/Search';

export type DistanceFilterSectionProps = {
  defaultDistanceRadius: number;
  onChange?: (newFilters: Partial<SearchFilters>) => void;
};

export const DistanceFilterSection = ({
  defaultDistanceRadius,
  onChange,
}: DistanceFilterSectionProps) => {
  const [value, setValue] = useState(defaultDistanceRadius);

  useEffect(() => {
    setValue(defaultDistanceRadius);
  }, [defaultDistanceRadius]);

  const handleSliderChange = (newValue: number) => {
    setValue(newValue);
  };

  const handleSliderChangeCompleted = (finalValue: number) => {
    onChange?.({ distanceRadius: finalValue });
  };

  return (
    <VStack w="full" px={3} space={2} justifyContent={'space-between'}>
      <Slider.Single
        unityLabel="mi"
        value={value}
        onChange={handleSliderChange as any}
        onChangeComplete={handleSliderChangeCompleted as any}
      />
    </VStack>
  );
};
