import {
  Box,
  Button,
  Hidden,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { UnorderedList } from 'ui/components/v2/UnorderedList';
import { colors } from 'ui/theme/colors';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export type SearchFeedEmptyStateProps = {};
export const SearchFeedEmptyState = ({}: SearchFeedEmptyStateProps) => {
  const { goToRoute } = useRouter();

  const handleHomeButtonPress = () => {
    goToRoute(PAGES.Home);
  };

  const title = useBreakpointValue({
    base: "We couldn't find any matches for your search. No worries, here are a few tips:",
    md: "We couldn't find any matches for your search.\nNo worries, here are a few tips:",
  });

  return (
    <VStack px={2} mt={6} alignItems={'center'}>
      <HStack
        w="fit-content"
        space={{ base: 0, md: 10 }}
        alignItems={'flex-start'}
      >
        <Hidden till="md">
          <Box py={8} px={2} w={'200px'} h="200px">
            <Illustration
              w={'full'}
              height={'full'}
              resizeMode="contain"
              name={ILLUSTRATIONS_NAME.PAPER_PLANE}
            />
          </Box>
        </Hidden>

        <VStack
          space={{ base: 4, md: 6 }}
          w={{ base: '100%', md: '480px' }}
          maxW={'480px'}
        >
          <Text
            fontSize={{ base: 'md', md: '18' }}
            fontWeight={500}
            color={colors.gray[600]}
          >
            {title}
          </Text>

          {/* List */}
          <UnorderedList>
            <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
              {`Double-check your spelling.`}
            </Text>
            <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
              {`Use broader or simpler words.`}
            </Text>
            <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
              {`Try different terms that mean the same thing.`}
            </Text>
            <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
              {`Reduce the number of filters you’ve applied.`}
            </Text>
            <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
              {`Browse by categories on our home screen.`}
            </Text>
          </UnorderedList>

          <VStack mt={4}>
            <Button
              variant="outline"
              onPress={handleHomeButtonPress}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <HStack space="10px" alignItems={'center'}>
                <Icon
                  icon={'home'}
                  size={'16px'}
                  color={colors.singletons.black}
                />

                <Text
                  fontSize={'sm'}
                  fontWeight={500}
                  color={colors.singletons.black}
                >
                  Go to home screen
                </Text>
              </HStack>
            </Button>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default SearchFeedEmptyState;
