import { useMemo } from 'react';
import { ButtonGroup } from 'ui/components/v2/ButtonGroup';
import { ActivitySemanticType } from 'ui/types/activities';

import { AttendanceType } from '~/types/interfaces/activity';
import { SearchFilters } from '~/zustand/store/Search';

export const ACTIVITY_TYPES_BUTTON_GROUP_MAP: Record<
  ActivitySemanticType,
  {
    icon: string;
    label: string;
  }
> = {
  [ActivitySemanticType.Event]: { label: 'Events', icon: 'calendar' },
  [ActivitySemanticType.TeamEvent]: { label: 'Team Events', icon: 'users' },
  [ActivitySemanticType.Action]: { label: 'Actions', icon: 'hand-pointer' },
  [ActivitySemanticType.Ongoing]: {
    label: 'Ongoing Opportunities',
    icon: 'repeat',
  },
};

export type ActivityTypeFilterSectionProps = {
  defaultActivityTypes: ActivitySemanticType[];
  onChange?: (newFilters: Partial<SearchFilters>) => void;
};
export const ActivityTypeFilterSection = ({
  defaultActivityTypes,
  onChange,
}: ActivityTypeFilterSectionProps) => {
  const handleChange = (values: string[]) => {
    const activityTypes = values as ActivitySemanticType[];

    const filterPayload: Partial<SearchFilters> = {
      activityTypes,
    };

    if (activityTypes.includes(ActivitySemanticType.Action)) {
      filterPayload.attendanceTypes = [AttendanceType.Online];
    }

    onChange?.(filterPayload);
  };

  const renderButtons = useMemo(
    () =>
      Object.entries(ACTIVITY_TYPES_BUTTON_GROUP_MAP).map(
        ([key, { label, icon }]) => (
          <ButtonGroup.RoundButton
            key={key}
            icon={icon}
            label={label}
            value={key}
          />
        ),
      ),
    [],
  );

  return (
    <ButtonGroup multi values={defaultActivityTypes} onChange={handleChange}>
      {renderButtons}
    </ButtonGroup>
  );
};
