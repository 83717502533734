import { addMonths } from 'date-fns';
import { ActivitySemanticType } from 'ui/types/activities';

import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';
import { AttendanceType } from '~/types/interfaces/activity';
import {
  SearchCollectionConfig,
  SearchCollectionSlug,
} from '~/types/interfaces/activity-search/types';

const today = new Date();
const DEFAULT_START_DATE = today;
const DEFAULT_END_DATE = addMonths(today, 6);
const DEFAULT_DISTANCE_RADIUS = 100; // Miles;

export const SEARCH_COLLECTION_CONFIG_MAP = new Map<
  SearchCollectionSlug,
  SearchCollectionConfig
>([
  [
    SearchCollectionSlug.Events,
    {
      label: 'Events',
      title: { large: 'Events' },
      subTitle: {
        large: 'Participate in organised events to make a direct impact',
        small: 'Participate in organised events',
      },
      filters: {
        relevance: SearchRelevanceSortBy.Nearest,
        activityTypes: [ActivitySemanticType.Event],
        attendanceTypes: [AttendanceType.Local],
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE,
        distanceRadius: DEFAULT_DISTANCE_RADIUS,
      },
      slug: SearchCollectionSlug.Events,
    },
  ],

  [
    SearchCollectionSlug.Actions,
    {
      label: 'Actions',
      title: { large: 'Actions' },
      subTitle: {
        large:
          'Contribute towards specific targets through action-oriented opportunities',
        small: 'Contribute towards specific targets',
      },
      filters: {
        relevance: SearchRelevanceSortBy.Newest,
        activityTypes: [ActivitySemanticType.Action],
        attendanceTypes: [AttendanceType.Online],
      },
      slug: SearchCollectionSlug.Actions,
    },
  ],

  [
    SearchCollectionSlug.TeamEvents,
    {
      label: 'Team Events',
      title: { large: 'Team Events' },
      subTitle: {
        large:
          'Participate in group activities designed for team-based volunteering',
        small: 'Participate in group activities',
      },
      filters: {
        relevance: SearchRelevanceSortBy.Newest,
        activityTypes: [ActivitySemanticType.TeamEvent],
        attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
        distanceRadius: DEFAULT_DISTANCE_RADIUS,
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE,
        teamsMaxSize: 25,
        teamsMinSize: 1,
      },
      slug: SearchCollectionSlug.TeamEvents,
    },
  ],

  [
    SearchCollectionSlug.Ongoing,
    {
      label: 'Ongoing Opportunities',
      title: { large: 'Ongoing Opportunities' },
      subTitle: {
        large:
          'Commit to long-term volunteering opportunities for sustained impact',
        small: 'Commit to long-term volunteering',
      },
      filters: {
        relevance: SearchRelevanceSortBy.Nearest,
        activityTypes: [ActivitySemanticType.Ongoing],
        attendanceTypes: [AttendanceType.Local],
        distanceRadius: DEFAULT_DISTANCE_RADIUS,
      },
      slug: SearchCollectionSlug.Ongoing,
    },
  ],

  [
    SearchCollectionSlug.EventsFromHome,
    {
      label: 'Events from home',
      title: { large: 'Events from home' },
      subTitle: {
        large:
          'Participate in events remotely, even from the confort of your own home',
        small: 'Participate in events remotely',
      },
      filters: {
        relevance: SearchRelevanceSortBy.Newest,
        activityTypes: [ActivitySemanticType.Event],
        attendanceTypes: [AttendanceType.Online],
      },
      slug: SearchCollectionSlug.EventsFromHome,
    },
  ],

  [
    SearchCollectionSlug.RemoteOngoing,
    {
      label: 'Ongoing from home',
      title: { large: 'Ongoing opportunities from home' },
      subTitle: {
        large: 'Take on long-term remote roles and volunteer from anywhere',
        small: 'Take on long-term remote role',
      },
      filters: {
        relevance: SearchRelevanceSortBy.Newest,
        activityTypes: [ActivitySemanticType.Ongoing],
        attendanceTypes: [AttendanceType.Online],
      },
      slug: SearchCollectionSlug.RemoteOngoing,
    },
  ],
]);
