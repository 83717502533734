import './styles.css';

import { Box, Text } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { RootPortal } from 'ui/components/v2/RootPortal';
import { colors } from 'ui/theme/colors';
type TooltipProps = {
  label?: string;
  children: React.ReactNode;
  width?: string | number;
  topOffSet?: number;
};

const Tooltip: React.FC<TooltipProps> = ({
  width,
  label,
  children,
  topOffSet = 32,
}) => {
  const [visible, setVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const showTooltip = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      setTooltipStyle({
        top: rect.top - topOffSet,
        left: rect.left + rect.width / 2,
        transform: 'translateX(-50%)',
      });

      setVisible(true);
    }
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener('mouseenter', showTooltip);
      wrapper.addEventListener('mouseleave', hideTooltip);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener('mouseenter', showTooltip);
        wrapper.removeEventListener('mouseleave', hideTooltip);
      }
    };
  }, []);

  return (
    <>
      <Box ref={wrapperRef} w={width}>
        {children}
      </Box>

      {visible && label ? (
        <RootPortal>
          <div
            className="tooltip-box"
            style={{ ...tooltipStyle, position: 'fixed' }}
          >
            <Text fontSize={'sm'} color={colors.singletons.white}>
              {label}
            </Text>
          </div>
        </RootPortal>
      ) : null}
    </>
  );
};

export default Tooltip;
