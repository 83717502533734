import './styles.css';

import { motion } from 'framer-motion';
import { Box, Pressable, Text, VStack } from 'native-base';
import { useCallback, useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { RoundButtonProps, RoundButtonSize, RoundButtonSizeMap } from './types';

export const RoundButton = (props: RoundButtonProps) => {
  const {
    icon,
    label,
    isActive,
    imageUrl,
    size = RoundButtonSize.lg,
    backgroundColor = `${colors.grey.bg}1A`,
    activeBackgroundColor = colors.singletons.black,
    onPress,
  } = props;

  const boxSize = useMemo(() => {
    return RoundButtonSizeMap[size];
  }, [size]);

  const handlePress = useCallback(() => {
    onPress?.();
  }, [onPress]);

  const bgColor = isActive ? activeBackgroundColor : backgroundColor;

  const iconColor = isActive
    ? colors.singletons.white
    : colors.singletons.black;

  return (
    <Pressable onPress={handlePress}>
      <VStack space={2} alignItems={'center'} w={'88px'}>
        <motion.div
          className="round-button-crop"
          initial={{ opacity: 0, rotateY: 0 }}
          animate={{ opacity: 1, rotateY: isActive ? 180 : 0 }}
          whileTap={{
            rotateY: 180,
            transition: { type: 'spring', stiffness: 500, damping: 30 },
          }}
          transition={{
            type: 'spring',
            stiffness: 100,
            duration: 0.8,
            backgroundColor: { duration: 0.5 },
          }}
          style={{
            height: boxSize,
            width: boxSize,
            backgroundColor: bgColor,
            perspective: 1000,
          }}
        >
          {/* Close button */}
          {isActive && (
            <Box
              position={'absolute'}
              top={0}
              right={-6}
              borderRadius={100}
              width={'20px'}
              height={'20px'}
              backgroundColor={'gray.200'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Icon
                enableAutoCustom
                icon={'x'}
                size={'16px'}
                color={colors.singletons.black}
              />
            </Box>
          )}
          {/* Icon */}
          <VStack justifyContent={'center'} alignItems={'center'} flex={1}>
            {icon ? (
              <Icon
                enableAutoCustom
                icon={icon}
                size={'24px'}
                color={iconColor}
              />
            ) : (
              <img
                className={`icon-image ${isActive ? 'active' : ''}`}
                src={imageUrl}
              />
            )}
          </VStack>
        </motion.div>

        {label && (
          <Text
            numberOfLines={2}
            fontSize={'xs'}
            fontWeight={500}
            textAlign={'center'}
            color={colors.singletons.darkText}
          >
            {label}
          </Text>
        )}
      </VStack>
    </Pressable>
  );
};
