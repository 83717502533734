import './styles.css';

import { Stack, Text, VStack } from 'native-base';
import { ActivityCard } from 'ui/components/v2/Cards/ActivityCard';
import { BackToTopButton } from 'ui/components/v2/FeedScaffold/components/BackToTopButton';
import { LoadTrigger } from 'ui/components/v2/FeedScaffold/components/LoadTrigger';
import { SearchActivityDefinition } from 'ui/types/interfaces/activity-definition';

import SearchFeedEmptyState from '~/components/Layouts/v2/SearchLayout/components/EmptyState';
import { Grid } from '~/components/v2/List/Grid';
import SearchFeedHeader from '~/pages/Authenticated/v2/Search/components/SearchFeed/FeedHeader';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';

import { FeedFooterCarouselsSuggestion } from '../CarouselSuggestions/components/FeedFooter';
import FeedSpanCarouselsSuggestion from '../CarouselSuggestions/components/FeedSpan';
import { SearchFeedController } from './controller';
import { DebugContainer } from './utils';
export type SearchFeedProps = {
  debugMode?: boolean;
};

export const SearchFeed = ({ debugMode = false }: SearchFeedProps) => {
  const {
    suggestions,
    hasNextPage,
    appliedFilters,
    infiniteScroll,
    activityDefinitions,
    filtersAppliedAt,
    handleScrollToTop,
    handleActivityCardPress,
  } = SearchFeedController();

  return (
    <VStack
      space={4}
      ref={infiniteScroll.topRef}
      key={String(filtersAppliedAt)}
    >
      <VStack zIndex={2}>
        <SearchFeedHeader searchText={appliedFilters.searchText} />
      </VStack>
      <VStack zIndex={1} position={'relative'}>
        <Grid
          isLoading={infiniteScroll.isLoading}
          isLoadingForwardData={infiniteScroll.isNextPageLoading}
          renderSkeletonItem={() => <ActivityCard.Skeleton />}
          data={activityDefinitions as SearchActivityDefinition[]}
          keyExtractor={(item) => (item as SearchActivityDefinition)._id}
          renderItem={(item) => (
            <>
              <ActivityCard.Factory
                onPress={() =>
                  handleActivityCardPress(item as SearchActivityDefinition)
                }
                activityDefinition={item as SearchActivityDefinition}
                selectedActivitySummary={
                  (item as SearchActivityDefinition).selectedActivity
                }
                ongoingNumberOfLines={7}
              />
              {debugMode && (
                <DebugContainer item={item as SearchActivityDefinition} />
              )}
            </>
          )}
          /* span */
          spanData={suggestions.candidateCollections}
          renderSpanItem={(item) => (
            <FeedSpanCarouselsSuggestion
              collectionSlug={item as SearchCollectionSlug}
            />
          )}
        />
        {/* Empty state */}
        {infiniteScroll.isEmpty && <SearchFeedEmptyState />}

        {/* Carousel collection suggestions */}
        {!hasNextPage && activityDefinitions.length ? (
          <FeedFooterCarouselsSuggestion />
        ) : null}

        {/* Out of data */}
        {infiniteScroll.isOutOfData && (
          <Text
            alignSelf={'center'}
            fontSize={'md'}
            color={'gray.400'}
            pt={4}
            pb={6}
          >
            No more activities
          </Text>
        )}
        {/* Bottom intersection */}
        <LoadTrigger
          triggerRef={infiniteScroll.loadTriggerRef}
          hasNext={!infiniteScroll.isOutOfData}
        />
      </VStack>
      {/* Back to top button */}
      {infiniteScroll.showBackToTopButton && (
        <Stack
          bottom={8}
          left={0}
          right={0}
          zIndex={999}
          margin={'auto'}
          position={'sticky'}
        >
          <BackToTopButton onPress={handleScrollToTop} />
        </Stack>
      )}
    </VStack>
  );
};
