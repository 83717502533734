import {
  Box,
  HStack,
  Image,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import { colors } from 'ui/theme/colors';

export type FeaturedActivityInfoData = {
  organizationName: string;
  organizationLogoUrl: string;
  activityTitle: string;
};

export type FeaturedActivityInfoSnippetProps = {
  data: FeaturedActivityInfoData;
  onPress?: VoidFunction;
};

export const FeaturedActivityInfoSnippet = ({
  data,
  onPress,
}: FeaturedActivityInfoSnippetProps) => {
  const { activityTitle, organizationLogoUrl, organizationName } = data;

  return (
    <Pressable
      py={3}
      w="full"
      flex={1}
      h="full"
      justifyContent={'center'}
      onPress={onPress}
    >
      <HStack space={3} justifyContent={'flex-start'} alignItems={'center'}>
        <Box
          width={'48px'}
          height={'48px'}
          borderWidth={1}
          borderColor={'gray.300'}
          backgroundColor={'white'}
          overflow={'hidden'}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={100}
        >
          <Image
            h="full"
            w="full"
            resizeMode="cover"
            alt={`Logo from activity ${organizationName}`}
            source={{ uri: organizationLogoUrl }}
            fallbackSource={{ uri: placeholderImage }}
          />
          <Stack width="100%" height="100%">
            <Skeleton width="100%" height="100%" />
          </Stack>
        </Box>

        <VStack flex={1} space={1}>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            color={colors.singletons.darkText}
            fontWeight={700}
            numberOfLines={2}
            lineHeight={'125%'}
          >
            {activityTitle}
          </Text>

          <Text
            fontSize={{ base: '10px', md: 'xs' }}
            color={colors.singletons.darkText}
            fontWeight={400}
            numberOfLines={1}
          >
            by {organizationName} teste
          </Text>
        </VStack>
      </HStack>
    </Pressable>
  );
};
