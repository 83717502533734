import { Auth } from 'aws-amplify';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Organization,
  OrganizationType,
} from 'ui/types/interfaces/organization';
import { UserProfile } from 'ui/types/interfaces/user';

import { COGNITO_ERRORS_CODE } from '~/constants/error.constants';
import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import { sendMessageToReactNativeWebview } from '~/pages/SignInMobile';
import AuthService from '~/services/resources/auth';
import UserService from '~/services/resources/user';
import { UserProfileService } from '~/services/resources/user-profile';
import { authSliceActions } from '~/store/slices/auth';
import { inAppSliceActions } from '~/store/slices/inApp';
import { ExecutionPlatform } from '~/store/slices/inApp/types';
import { useUserProfile } from '~/zustand/store/UserProfile';

import { IUser } from '../../types/interfaces/user';
import useAnalytics from '../useAnalytics/useAnalytics';

export const useAuth = () => {
  const { goToRoute, replaceRoute } = useRouter();
  const { identify } = useAnalytics();

  const dispatch = useDispatch();
  const { handleUpdateUserPermissions } = usePermissions();

  const { acceptInvite } = useAppSelector(({ redirect }) => redirect);
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const setAvailableUserProfiles = useUserProfile(
    (state) => state.setAvailableUserProfiles,
  );

  const handleGetUserInformation = useCallback(
    async ({
      shouldFetchAttributes,
      ecosystemId,
    }: {
      shouldFetchAttributes: boolean;
      ecosystemId?: string;
    }): Promise<IUser | null> => {
      let userData = null;
      try {
        let updatePayload = {};
        const currentSession = await Auth.currentSession();
        const idToken = currentSession.getIdToken().getJwtToken();

        if (!idToken) {
          return userData;
        }
        const { data: responseMeData } = await UserService.me();
        userData = responseMeData;

        if (shouldFetchAttributes) {
          const responseAuthUser = await Auth.currentAuthenticatedUser();
          updatePayload = {
            userAttributes: responseAuthUser.attributes,
          };
        }

        dispatch(
          authSliceActions.update({
            isLoading: true,
          }),
        );

        if (responseMeData._id && responseMeData.cognitoId) {
          const availableUserProfiles = responseMeData?.userProfiles || [];
          // Slowing changing store provider to zustand
          setAvailableUserProfiles(availableUserProfiles as UserProfile[]);

          let userProfile = availableUserProfiles?.[0];

          if (selectedUserProfile && !ecosystemId) {
            userProfile = selectedUserProfile;
          } else if (ecosystemId) {
            const findUserProfile = availableUserProfiles?.find(
              (profile) => profile.ecosystem === ecosystemId,
            );
            if (findUserProfile) {
              userProfile = findUserProfile;
            }
          } else {
            const employeeProfiles = availableUserProfiles?.filter(
              (profile) => {
                const organization = profile?.ecosystemSummary?.organization;
                if (typeof organization === 'string') return false;

                return (
                  (organization as Partial<Organization>)?.type ===
                  OrganizationType.COMPANY
                );
              },
            );
            if (employeeProfiles?.length) {
              userProfile = employeeProfiles[0];
            }
          }

          if (userProfile) {
            await handleUpdateUserPermissions(userProfile._id);

            updatePayload = {
              ...updatePayload,
              selectedUserProfile: userProfile,
              user: {
                ...responseMeData,
              },
            };

            dispatch(authSliceActions.update(updatePayload));

            if (userProfile?._id) {
              if (acceptInvite.externalId) {
                await UserProfileService.updateUserExternalId(userProfile._id, {
                  externalId: acceptInvite.externalId,
                });
              }
            }
          }
        }
      } catch (error) {
        toast.error(`Error on trying to get user data.`);
        console.error(`Error on trying to get user data.`, error);
      } finally {
        dispatch(
          authSliceActions.update({
            isLoading: false,
          }),
        );
        return userData;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleUpdateUserPermissions],
  );

  const handleGetUserAfterAcceptInvite = async (
    appId?: string,
    ecosystemId?: string,
  ) => {
    const redirectURL = appId
      ? PAGES.InAppRoot.replace(':appId', appId)
      : PAGES.AllApps;
    await handleGetUserInformation({
      shouldFetchAttributes: false,
      ecosystemId,
    });
    replaceRoute(redirectURL);
  };

  const handleSignIn = async (signInData: {
    email: string;
    password: string;
  }) => {
    try {
      dispatch(authSliceActions.update({ isLoading: true }));

      const response = await AuthService.signInWithEmailAndPassword(signInData);
      dispatch(authSliceActions.signIn(response));

      const userData = await handleGetUserInformation({
        shouldFetchAttributes: false,
      });

      // Register user id to firebase analytics
      if (userData !== null && userData?._id) {
        identify(userData?._id);
      }

      dispatch(
        authSliceActions.update({
          isLoading: false,
          isAuthenticated: true,
        }),
      );

      replaceRoute(PAGES.Root);
    } catch (error) {
      if (error instanceof Error) {
        if (error?.code === COGNITO_ERRORS_CODE.USER_NOT_CONFIRMED_EXCEPTION) {
          await Auth.resendSignUp(signInData.email);
          goToRoute(PAGES.AccessCode, {
            state: {
              email: signInData.email,
              password: signInData.password.trim(),
            },
          });
          return;
        }
        toast.error(error.message);
      } else {
        toast.error(COMMON_MESSAGES.UNKNOWN_ERROR);
      }
    }
  };

  const handleSignInMobile = useCallback(
    async ({
      appId,
      redirectUserToInAppView = true,
    }: {
      appId: string;
      redirectUserToInAppView: boolean;
    }) => {
      try {
        dispatch(
          authSliceActions.update({
            isLoading: true,
          }),
        );
        const response = await Auth.currentAuthenticatedUser();
        dispatch(authSliceActions.signIn(response));

        await handleGetUserInformation({ shouldFetchAttributes: false });
        sendMessageToReactNativeWebview('info_user_signed_in');

        dispatch(inAppSliceActions.setPlatform(ExecutionPlatform.WEBVIEW));

        if (redirectUserToInAppView) {
          replaceRoute(PAGES.InAppRoot.replace(':appId', appId));
        }
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          sendMessageToReactNativeWebview('error_tag', error.message);
        }
        dispatch(authSliceActions.logout({}));
      } finally {
        dispatch(
          authSliceActions.update({
            isLoading: false,
          }),
        );
      }
    },
    [dispatch, handleGetUserInformation, replaceRoute],
  );

  return {
    handleSignIn,
    handleGetUserInformation,
    handleGetUserAfterAcceptInvite,
    handleSignInMobile,
  };
};
