import './styles.css'; // Import the CSS file

import { Hidden, ScrollView, VStack } from 'native-base';

import CategoriesButtonGroup from '~/pages/Authenticated/v2/InApp/components/CategoriesSlider/CategoriesButtonGroup';
import { useCategoriesSliderScrollController } from '~/pages/Authenticated/v2/InApp/components/CategoriesSlider/controllers/scroll-controller';

import { useCategoriesSliderController } from './controllers/controller';
import NavButton from './NavButton';

export type CategoriesSliderProps = {
  isDisabled?: boolean;
};
export const CategoriesSlider = ({ isDisabled }: CategoriesSliderProps) => {
  const {
    selectedCategory,
    searchCategoriesMap,
    brandColor,
    handleCategoryChange,
  } = useCategoriesSliderController();

  const {
    goLeft,
    goRight,
    refScroll,
    showLeftButton,
    showRightButton,
    handleLayoutChange,
    handleScrollPositionChange,
  } = useCategoriesSliderScrollController({
    dataLength: searchCategoriesMap?.size || 0,
  });

  return (
    <VStack position={'relative'} w="full" px={{ base: 0, sm: 2 }}>
      <Hidden till="sm">
        <>
          {showLeftButton && (
            <NavButton anchor="left" onPress={goLeft} iconName="chevron-left" />
          )}

          {showRightButton && (
            <NavButton
              anchor="right"
              onPress={goRight}
              iconName="chevron-right"
            />
          )}
        </>
      </Hidden>

      <ScrollView
        zIndex={1}
        horizontal
        ref={refScroll}
        onLayout={handleLayoutChange}
        onScroll={(event) =>
          handleScrollPositionChange(event.nativeEvent.contentOffset.x)
        }
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        py={1}
      >
        <CategoriesButtonGroup
          selected={selectedCategory}
          categoriesMap={searchCategoriesMap}
          buttonBackgroundColor={brandColor}
          onChange={handleCategoryChange}
        />
      </ScrollView>

      {isDisabled && <div className="float-blocker" />}
    </VStack>
  );
};

export default CategoriesSlider;
