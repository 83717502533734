import { RoundButton } from 'ui/components/v2/Buttons/RoundButton';
import { RoundButtonProps } from 'ui/components/v2/Buttons/RoundButton/types';

import { ButtonGroupItemProps } from '../types';

export interface ButtonGroupRoundButtonProps
  extends RoundButtonProps,
    ButtonGroupItemProps {}

export const ButtonGroupRoundButton = ({
  icon,
  imageUrl,
  size,
  label,
  isSelected,
  backgroundColor,
  activeBackgroundColor,
  onPress,
}: ButtonGroupRoundButtonProps) => {
  return (
    <RoundButton
      icon={icon}
      size={size}
      label={label}
      imageUrl={imageUrl}
      isActive={isSelected}
      backgroundColor={backgroundColor}
      activeBackgroundColor={activeBackgroundColor}
      onPress={onPress}
    />
  );
};
